<template>
    <div class="wrapper">
        <RadiusLoader v-if="isLoading" />
        <support-card
            v-else
            :index="0"
            :ticket="ticket"
            type="guest"
            :is-open="true"
        />
    </div>
</template>

<script>
import Api from '@/helpers/api/index.js';
import { eventBus } from '@/helpers/event-bus'

import RadiusLoader from '@/components/common/RadiusLoader.vue'
import SupportCard from '@/components/account/Cards/SupportCard/SupportCard';

export default {
    components: {
        SupportCard,
        RadiusLoader
    },
    data() {
        return {
            isLoading: true,
            ticket: {}
        }
    },
    computed: {
        ticketId() {
            return this.$route.query.ticketid
        },
        hash() {
            return this.$route.query.hash
        }
    },
    async created() {
        if (!this.ticketId || !this.hash) {
            this.$router.push({ name: 'support_form' })
            return
        }
        await this.getTicket()
    },
    methods: {
        async getTicket() {
            try {
                this.isLoading = true
                const { data } = await Api.get('/api/support/guest/fetch-ticket', { ticketid: this.ticketId, hash: this.hash })
                this.ticket = data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isLoading = false
            }
        }
    }
}
</script>
