<template>
    <div class="page_support">
        <div class="wrapper">
            <div class="">
                <breadcrumbs
                    :settings="settingsBreadcrumbs"
                >
                    <!-- <template
                        #rightSide
                    >
                        <div class="support_ticket_sidebar">
                            <img
                                :src="require('Images/helpcenter-sidebar.svg')"
                                alt="help center"
                            >
                        </div>
                    </template> -->
                </breadcrumbs>
                <support-ticket-form />
            </div>
        </div>
        <confirm-modal ref="confirm-modal" />
    </div>
</template>

<script>
import SupportTicketForm from '@/components/support/SupportTicketForm';
import ConfirmModal from '@/components/modals/ConfirmModal';
import Breadcrumbs from '@/components/Breadcrumbs';

export default {
    components: {
        SupportTicketForm,
        ConfirmModal,
        Breadcrumbs
    },
    props: {
        propOrderId: {
            type: String
        }
    },
    metaInfo: {
        title: 'Help Center'
    },
    computed: {
        apiContent() {
            return process.env.VUE_APP_API_CONTENT
        },
        settingsBreadcrumbs() {
            const { breadcrumbs } = this.$route.meta
            return {
                title: breadcrumbs.name,
                excerpt: breadcrumbs.excerpt,
                additionalClass: breadcrumbs.additionalClass
            }
        }
    }
}
</script>
